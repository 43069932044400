<template>
  <div>
    <div
      :style="
        $router.history.current.path == '/wxpay' ||
        $router.history.current.path == '/agreement'
          ? 'display: none;'
          : ''
      "
      :class="
        hideClassPageHeadArr.indexOf($router.history.current.path) + 1
          ? ''
          : 'page_head'
      "
      class="fixed-top"
    >
      <div class="topnav">
        <vhead></vhead>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
import head from "@/components/head/head";

export default {
  data() {
    return {
      hideClassPageHeadArr: [""],
    };
  },
  components: {
    vhead: head,
  },
};
</script>

<style>
</style>